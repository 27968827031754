
import { defineComponent } from 'vue';
import Case from '@/types/Case'
import { useStore } from "vuex";
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Detail',
  setup() {
    const store = useStore();
    const route = useRoute()
    const item: Case = store.getters.getCaseById(route.params.id)

    return {
      item
    }
  }
  
})
